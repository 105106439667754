import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Privacy from "./components/privacy";
import Terms from "./components/terms";

import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

function App() {
  return (
      <Router>
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
            </Routes>
        </div>
      </Router>
  );
}

export default App;
