import React from "react";
import FooterOther from "./footer-other";

const Privacy = () => {
  return (
    <div>
      <div className="banner1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src="image-1.svg" alt="findersseat_logo" />
            </div>
            <div className="col-12">
              <h1 className="privacyterms">Privacy Policy</h1>
              <h4>1. Introduction</h4>
              <p className="privacyandterms">
                Welcome to Findersseat ("we," "our," or "us"). We prioritize the
                privacy and security of our users ("you" or "users"). This
                Privacy Policy outlines how we gather, utilize, share, and
                protect your personal information when you engage with our{" "}
                <a href="https://play.google.com/store/apps/details?id=com.findersseat.app">
                  Android
                </a>{" "}
                application and services.
              </p>
              <h4>2. Information We Collect</h4>
              <p className="privacyandterms">
                To provide you with a personalized matchmaking experience, we
                collect various types of personal information. This includes:
              </p>
              <p className="privacyandterms">User-provided Information:</p>
              <p className="privacyandterms">
                {" "}
                - Name, Email Address, and Phone Number: We request this
                information during the registration process to establish your
                identity, manage your account, and facilitate effective
                communication with potential matches.
              </p>
              <p className="privacyandterms">
                {" "}
                - Hair Color, Age, Height, Ethnicity, Country, and Date of
                Birth: By understanding these details, we can improve the
                accuracy of our match suggestions and help you connect with
                individuals who share common attributes and interests.
              </p>
              <p className="privacyandterms">
                Automatically Collected Information:
              </p>
              <p className="privacyandterms">
                {" "}
                - Usage Data: As you navigate through our app, we automatically
                collect data about your interactions, such as pages visited,
                features used, and the time spent on the app. This information
                aids us in refining the app's functionality and enhancing user
                experience.
              </p>
              <h4>3. Why We Collect This Information</h4>
              <p className="privacyandterms">
                We collect your personal information for the following specific
                reasons:
              </p>
              <p className="privacyandterms">
                - Precision in Matchmaking: The information you provide allows
                us to create better match recommendations, resulting in a higher
                likelihood of finding a compatible partner who aligns with your
                preferences and values.
              </p>
              <p className="privacyandterms">
                - Effective Communication: Your contact details play a crucial
                role in enabling seamless communication between users who
                express mutual interest in each other.
              </p>
              <p className="privacyandterms">
                - Enhancing Our Services: Analyzing usage data empowers us to
                make informed decisions about refining the app's features and
                user interface. This continuous improvement benefits the overall
                user experience.
              </p>
              <h4>4. Sharing of Information</h4>
              <p className="privacyandterms">
                We handle your personal information with utmost confidentiality.
                We do not disclose your personal information to external parties
                except as outlined in this policy or with your explicit consent.
                Your information may be shared with:
              </p>
              h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2 h2
              h2 h2 h2 h2
              <p className="privacyandterms">
                - Match Suggestions: Your hair color, age, height, ethnicity,
                country, and similar data are exclusively used to suggest
                potential matches. This data remains private and secure.
              </p>
              <p className="privacyandterms">
                - Facilitating Communication: In cases of mutual interest, we
                may share selected profile details and your first name to
                facilitate meaningful communication.
              </p>
              <p className="privacyandterms">
                - Legal Compliance: We may disclose your information when
                required by law, court order, or legal proceedings.
              </p>
              <h4>5. Your Choices</h4>
              <p className="privacyandterms">
                You retain rights concerning your data:
              </p>
              <p className="privacyandterms">
                - Access: By accessing your profile settings in the app, you can
                review and update your personal information.
              </p>
              <p className="privacyandterms">
                - Withdrawal: You can withdraw consent for processing your
                information by deleting your account. Note that this might
                affect access to certain features.
              </p>
              <h4>6. Security</h4>
              <p className="privacyandterms">
                We implement rigorous measures to safeguard your personal
                information from unauthorized access, modification, disclosure,
                or destruction. However, it's essential to acknowledge that no
                method of transmitting data over the internet or electronic
                storage is entirely immune to breaches.
              </p>
              <h4>7. Changes to this Privacy Policy</h4>
              <p className="privacyandterms">
                We reserve the right to update this Privacy Policy as necessary
                to reflect changes in our practices and services. We commit to
                notifying you of any significant changes and obtaining your
                consent if required by relevant laws.
              </p>
              <h4>8. Contact Us</h4>
              <p className="privacyandterms">
                For any inquiries about this Privacy Policy, feel free to reach
                out to us at{" "}
                <a href="mailto:support@datingapp.com">support@datingapp.com</a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterOther />
    </div>
  );
};

export default Privacy;
