import React from "react";
import Footer from "./footer";

const Home = () => {
  return (
    <div>
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src="/image-1.svg" alt="findersseat_logo" />
            </div>
            <div className="col-6 pt-5">
              <h1>Meet new and interesting people.</h1>
              <p>Download on</p>
              <div>
                <img src="/Frame 682.svg" alt="Google Play" />
                <img src="/Frame 683.svg" alt="Apple Store" />
              </div>
            </div>
            <div className="col-6">
              <div className="banner-img pt-5 image-background d-flex gap-4">
                <img className="w-100" src="/banner-img.png" alt="Mobile 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="adjustment">
                Exclusive Features for Pro Findersseat Members
              </h3>
            </div>
            <div className="col-4">
              <div className="feature-item feature-style">
                <div className="feature-position">
                  <img className="pb-3" src="/Boost.svg" alt="boost" />
                  <h5>Boost your profile</h5>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="feature-item feature-style">
                <div className="feature-position">
                  <img className="pb-3" src="/gift - filled.svg" alt="boost" />
                  <h5>Send many gifts</h5>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="feature-item feature-style">
                <div className="feature-position">
                  <img className="pb-3" src="/Sticker-filled.svg" alt="boost" />
                  <h5>Get additional stickers</h5>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="feature-item feature-style">
                <div className="feature-position">
                  <img className="pb-3" src="/heart.svg" alt="boost" />
                  <h5>High chances of friendship</h5>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="feature-item feature-style">
                <div className="feature-position">
                  <img className="pb-3" src="/Layers.svg" alt="boost" />
                  <h5>Put yourself at top in search</h5>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="feature-item feature-style">
                <div className="feature-position">
                  <img className="pb-3" src="/goal.svg" alt="boost" />
                  <h5>BGet seen 100x in Discover</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
