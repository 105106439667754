import React from "react";

const FooterOther = () => {
  return (
    <footer className="new-color">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <img src="/image 2.svg" alt="" />
            <ul>
              <li className="social-icons">
                <img src="/ri_facebook-fill-black.svg" alt="" />
              </li>
              <li className="social-icons">
                <img src="/ri_linkedin-fill-black.svg" alt="" />
              </li>
              <li className="social-icons">
                <img src="/mdi_instagram-black.svg" alt="" />
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul className="pt-5">
              <li className="footer-content">
                <a className="termsandconstions-a" href="/terms-and-conditions">
                  Terms & Conditions
                </a>
              </li>
              <li className="footer-content">
                <a className="termsandconstions-a" href="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="copyright-position">
              <img src="/ph_copyright-black.svg" alt="" />
              <p className="terms-para-small">2023 Findersseat Pvt Ltd</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOther;
