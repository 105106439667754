import React from "react";
import FooterOther from "./footer-other";

const Terms = () => {
  return (
    <div>
      {" "}
      <div className="banner1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src="/image-1.svg" alt="findersseat_logo" />
            </div>
            <div className="col-12">
              <h1 className="privacyterms">Terms & Conditions</h1>
              <p className="privacyandterms">
                <strong>Acceptance of Terms:</strong> By accessing or using the
                dating app, you agree to be bound by these Terms and Conditions,
                as well as the Privacy Policy. If you do not agree to these
                terms, you should not use the app.
              </p>

              <h4>Eligibility</h4>
              <p className="privacyandterms">
                The dating app is intended for users aged 18 years and above. By
                using the app, you confirm that you are of legal age in your
                jurisdiction.
              </p>

              <h4>User Accounts and Profiles</h4>
              <p className="privacyandterms">
                You are responsible for maintaining the confidentiality of your
                account login credentials and any activities that occur under
                your account.
              </p>
              <p className="privacyandterms">
                You agree to provide accurate, truthful, and complete
                information while creating your profile.
              </p>
              <p className="privacyandterms">
                You shall not impersonate anyone or use false information to
                create a profile.
              </p>

              <h4>Content and Behavior</h4>
              <p className="privacyandterms">
                You are solely responsible for the content you post on the app,
                including profile information, photos, and messages.
              </p>
              <p className="privacyandterms">
                You agree not to post content that is offensive, sexually
                explicit, discriminatory, harassing, or violates any applicable
                laws or regulations.
              </p>
              <p className="privacyandterms">
                The app reserves the right to review, moderate, and remove any
                content that violates these Terms and Conditions.
              </p>

              <h4>Privacy and Data Collection</h4>
              <p className="privacyandterms">
                The app collects and processes personal data in accordance with
                the Privacy Policy. By using the app, you consent to the data
                practices outlined in the Privacy Policy.
              </p>

              <h4>Prohibited Activities</h4>
              <p className="privacyandterms">
                You shall not use the app for any illegal, unauthorized, or
                prohibited activities, including spamming, solicitation, or
                commercial use without prior authorization.
              </p>
              <p className="privacyandterms">
                You shall not use the app to transmit viruses, malware, or any
                harmful content.
              </p>

              <h4>Safety and Offline Meetings</h4>
              <p className="privacyandterms">
                You agree to exercise caution when interacting with other app
                users, especially when arranging offline meetings.
              </p>
              <p className="privacyandterms">
                The app is not responsible for the conduct or actions of its
                users during offline interactions.
              </p>

              <h4>Intellectual Property</h4>
              <p className="privacyandterms">
                The app and its content, including trademarks, logos, and
                designs, are protected by intellectual property laws and are the
                property of the app's owner.
              </p>
              <p className="privacyandterms">
                You shall not use, copy, reproduce, or modify any app content
                without prior authorization.
              </p>

              <h4>Modifications and Updates</h4>
              <p className="privacyandterms">
                The app reserves the right to modify, update, or discontinue any
                part of the service without notice.
              </p>
              <p className="privacyandterms">
                These Terms and Conditions may be updated from time to time, and
                continued use of the app constitutes acceptance of the revised
                terms.
              </p>

              <h4>Disclaimer of Warranty</h4>
              <p className="privacyandterms">
                The app provides its services "as is" without any warranties or
                guarantees. The app does not guarantee the accuracy,
                availability, or reliability of its services.
              </p>

              <h4>Limitation of Liability</h4>
              <p className="privacyandterms">
                The app shall not be liable for any direct, indirect,
                incidental, or consequential damages arising from the use or
                inability to use the app.
              </p>

              <h4>Governing Law and Dispute Resolution</h4>
              <p className="privacyandterms">
                These Terms and Conditions shall be governed by the laws of
                [Jurisdiction], and any disputes shall be resolved through
                arbitration or in the courts of [Jurisdiction].
              </p>

              <h4>Termination</h4>
              <p className="privacyandterms">
                The app reserves the right to terminate or suspend any user
                account at its discretion and without notice for violation of
                these Terms and Conditions.
              </p>

              <h4>Contact</h4>
              <p className="privacyandterms">
                For any questions, inquiries, or support, you can contact the
                app's support team at{" "}
                <a href="mailto:support@datingapp.com">support@datingapp.com</a>
                .
              </p>

              <p className="privacyandterms">
                By using the dating app, you acknowledge that you have read,
                understood, and agreed to the Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterOther />
    </div>
  );
};

export default Terms;
