import React from "react";
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <img src="/image 2.svg" alt="" />
            <ul>
              <li className="social-icons">
                <img src="/ri_facebook-fill.svg" alt="" />
              </li>
              <li className="social-icons">
                <img src="/ri_linkedin-fill.svg" alt="" />
              </li>
              <li className="social-icons">
                <img src="/Vector (1).svg" alt="" />
              </li>
            </ul>
          </div>
          <div className="col-6">
            <ul className="pt-5">
              <li className="footer-content">
                <a href="/terms-and-conditions">Terms & Conditions</a>
              </li>
              <li className="footer-content">
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="copyright-position">
              <img src="/ph_copyright-light.svg" alt="" />
              <p className="para-small">2023 Findersseat Pvt Ltd</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
